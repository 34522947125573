import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withError } from '../../../../shared/components/error-boundary';
import withWindowResize from '../../../../ui/components/window-resize';
import ContentCarousel from '../../content-carousel/content-carousel';
import Icon from '../../../../ui/components/icon';
import { isTechAtWork, TECH_AT_WORK_TAG } from '../../../components/utils';

import { doSearch } from '../../../../search-filter/search-filter-actions';
import { searchBlogs, articleViewPageType } from '../../../blog-list-actions';
import { Typography, Link } from '@hpstellar/core';

class PopularArticles extends Component {
    componentDidMount() {
        try {
            let { topBlogs, postFilters, tags, categories, page } = this.props;

            let isTaw = !!isTechAtWork(tags, categories);
            let prevIsTaw = !!(postFilters && isTechAtWork(postFilters.categories, postFilters.tags));

            if (!topBlogs || topBlogs.length < 1 || (prevIsTaw !== isTaw && page !== articleViewPageType)) {
                let post_filters = isTaw
                    ? {
                          tags: [TECH_AT_WORK_TAG],
                          categories: [TECH_AT_WORK_TAG],
                      }
                    : {};
                this.searchBlogs(post_filters);
            }
        } catch (e) {}
    }

    componentDidUpdate(prevProps, prevState) {
        let { tags, categories, postFilters, vanityUrl, page, searchId } = this.props;
        let { vanityUrl: prevVanityUrl } = prevProps;

        try {
            let isTaw = isTechAtWork(tags, categories);
            let prevIsTaw = isTechAtWork(postFilters.tags, postFilters.categories);

            let post_filters = isTaw
                ? {
                      tags: [TECH_AT_WORK_TAG],
                      categories: [TECH_AT_WORK_TAG],
                  }
                : {};

            let filterHasChanged = postFilters && JSON.stringify(post_filters) !== JSON.stringify(postFilters);

            // if it's an on an article page (page is 'tech-takes-article-view'), wait until the tags/categories are fetched
            // before determining whether the popular articles should be replaced. The categories and tags are available when vanityUrl is a string
            // and the prevVanityUrl is undefined
            if (
                page === searchId &&
                (page !== articleViewPageType || (vanityUrl && !prevVanityUrl)) &&
                isTaw !== prevIsTaw &&
                filterHasChanged
            ) {
                this.searchBlogs(post_filters);
            }
        } catch (e) {}
    }

    getLastThreeMonths = () => {
        const today = new Date();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();
        const numOfMonths = 12;

        let dateArr = [];
        for (let i = 0; i < 3; i++) {
            const yearAndMonth =
                currentMonth - i < 0
                    ? '' + (currentYear - 1) + (numOfMonths + (currentMonth - i))
                    : '' + currentYear + (currentMonth - i);
            dateArr.push(yearAndMonth);
        }

        return dateArr;
    };

    searchBlogs = post_filters => {
        //search for the top 10 articles
        const filters = { blogArchive: 'false', templateKey: 'blog', hosted: 'true' };
        const { page } = this.props;
        const sort = { visitCount: 'desc' };
        const size = 10;
        const aggregates = {
            all_years: 'blogYear',
            all_months: 'blogMonth',
            all_categories: 'categories',
        };
        const aggregateFilters = Object.keys(aggregates);
        const bucketSize = 30;
        const from = 0;
        this.props.searchPopularArticles(
            filters,
            post_filters,
            aggregates,
            aggregateFilters,
            sort,
            size,
            from,
            bucketSize,
            page
        );
    };

    render() {
        const { device, width, topBlogs } = this.props;
        const showCarousel = width && width < 1024 && width > 759;

        return (
            <div className="popular-blogs">
                <ul style={{ textAlign: 'left' }}>
                    {showCarousel ? (
                        <ContentCarousel header={'Popular Articles'} relatedBlogs={{ hits: { hits: topBlogs } }} />
                    ) : (
                        <React.Fragment>
                            <Typography variant="boldL" tag="h5" className="title">
                                Popular articles
                            </Typography>
                            {topBlogs &&
                                topBlogs.map((blog, index) => {
                                    const { blogTitle, blogThumbnail, vanityUrl } = (blog && blog._source) || {};
                                    if (blogTitle && blogThumbnail.src) {
                                        return (
                                            <li key={index} className={`popular-blog${index > 4 ? ' tablet' : ''}`}>
                                                <Icon size={20} name="right_small" />
                                                <Link
                                                    to={`${process.env.BASENAME}/${vanityUrl}`}
                                                    variation="body"
                                                    weight="light"
                                                    size="small"
                                                    color="dark"
                                                >
                                                    <div
                                                        className={`popular-blog-title${
                                                            (topBlogs.length > 5 && index === 4) ||
                                                            index === topBlogs.length - 1
                                                                ? ' bottom-border-none'
                                                                : ''
                                                        }`}
                                                    >
                                                        {blogTitle}
                                                    </div>
                                                </Link>
                                            </li>
                                        );
                                    }
                                })}
                        </React.Fragment>
                    )}
                </ul>
            </div>
        );
    }
}

const mapStateToProps = state => {
    try {
        let { searchResults } = state.searchFilter.topBlogs;
        let { hits, post_filters, searchId } = searchResults;
        return {
            topBlogs: hits && hits.hits,
            postFilters: post_filters,
            searchId,
        };
    } catch (e) {}

    return {
        topBlogs: null,
        postFilters: null,
    };
};

const mapDispatchToProps = dispatch => ({
    searchPopularArticles: (
        filters,
        topBlogFilters,
        aggregates,
        aggregateFilters,
        sortTopBlogs,
        sizeTopBlogs,
        from,
        bucketSize,
        searchId
    ) =>
        dispatch(
            doSearch('topBlogs', () =>
                searchBlogs(
                    filters,
                    topBlogFilters,
                    aggregates,
                    aggregateFilters,
                    sortTopBlogs,
                    sizeTopBlogs,
                    from,
                    bucketSize,
                    searchId
                )
            )
        ),
});
export default connect(mapStateToProps, mapDispatchToProps)(withError(withWindowResize(PopularArticles)));
