import React, { Component } from 'react';

import Icon from '../../../../ui/components/icon';
import {Link, Typography} from '@hpstellar/core';

class ArticleArchives extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleArchives: [],
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.articleArchives && Object.keys(nextProps.articleArchives).length) {
            const { hits: articleArchives } = nextProps.articleArchives;
            const { hits, total: totalHits } = articleArchives || {};

            let blogs =
                hits &&
                hits.map(hit => {
                    return hit['_source'];
                });

            return {
                articleArchives: blogs,
            };
        }
        return null;
    }

    render() {
        const { articleArchives } = this.state;

        return (
            <div className="article-archives">
                {articleArchives && articleArchives.length > 0 && (
                    <span>
                        <Typography variant="boldL" tag="h5">
                            Article archives
                        </Typography>
                        <hr />
                        <ul>
                            {articleArchives.map((article, index) => {
                                const { vanityUrl, blogTitle } = article;
                                return (
                                    <li key={index} className="article-archive-item-container">
                                        <Icon size={20} name="right_small" />
                                        <Link
                                            to={`/${vanityUrl}`}
                                            variation="body"
                                            weight="light"
                                            size="small"
                                            color="dark"
                                            className="article-archive-item"
                                        >
                                            {blogTitle}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </span>
                )}
            </div>
        );
    }
}

export default ArticleArchives;
