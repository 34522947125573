import React, { Component } from 'react';

import BlogAdContainer from '../../components/ads/ad-container';
import PopularArticles from './popular-articles/popular-articles';
import ArticleArchivesContainer from './article-archives/article-archives-container';
import TagsContainer from './tags/tags-container';
import SubscribeButton from './subscribe-btn';
import AboveTheFoldOnlyServerRender from '../../../shared/components/above-the-fold-only-server-render';
import DynamicSearchBox from '../../components/search-box/dynamic-search-box';
import withWindowResize from '../../../ui/components/window-resize';
import { Helpers } from '../../../core';

import '../../components/right-rail/right-rail.less';
import './right-rail-v2.less';
import { Typography } from '@hpstellar/core';

const TagsSection = withWindowResize(
    ({
        page,
        tagsHeader,
        bannerMeta,
        tags,
        categories,
        customBreakpoints,
        width,
        height,
        hideBlogAd,
        hideSearchBox,
        processSearchBoxResponse,
        originCountryCode,
        vanityUrl,
    }) => {
        let isArticlePage = page === 'tech-takes-article-view';
        const breakpoint = isArticlePage ? 'blog-v2-article' : 'blog-v2-list';
        let device = customBreakpoints && customBreakpoints[breakpoint];
        let isBlogDesktopView = device === 'desktop' || (isArticlePage && width > 1023);
        const className = isBlogDesktopView ? `blog-tags-desktop${!hideBlogAd ? ' blog-ad' : ''}` : 'blog-tags-mobile';
        const showBlogAd = !hideBlogAd && (isBlogDesktopView || page !== 'tech-takes-article-view');
        const blogAdClass = `blog-ad-${device} blog-ad-${page}`;

        return (
            <React.Fragment>
                {isBlogDesktopView && <PopularArticles tagsHeader={tagsHeader} tags={tags} />}
                <div className={className}>
                    {/** Only show the ad in the tags section if:
                     *    1. Desktop view of an Article.
                     *    2. Any device (desktop/tablet/mobile) of an article listing page.
                     *  Once the desktop ad is loaded for Article View pages, it should remain (but hidden) on the page
                     *  and shouldn't force the BlogAdBanner to unmount/remount.
                     */}
                    {showBlogAd && (
                        <div className={blogAdClass}>
                            <BlogAdContainer
                                bannerType="rightRailBanner"
                                uniqueIdentifier="blog-right-rail-banner"
                                bannerMeta={bannerMeta}
                                type={device}
                                breakpoint={breakpoint}
                                v2
                            />
                        </div>
                    )}
                    <div className="blog-tags">
                        <Typography variant="boldL" tag="h5">
                            {tagsHeader}
                        </Typography>
                        {isBlogDesktopView && <hr />}
                        <TagsContainer header={tagsHeader} tags={tags} />
                    </div>
                </div>
                {!isBlogDesktopView && <PopularArticles tagsHeader={tagsHeader} tags={tags} />}
                {!hideSearchBox && <DynamicSearchBox page={page} processSearchBoxResponse={processSearchBoxResponse} />}
                <ArticleArchivesContainer />
                {showBlogAd && isBlogDesktopView && (
                    <div className={blogAdClass}>
                        <BlogAdContainer
                            bannerType="bannerSkyscraper"
                            uniqueIdentifier="blog-right-rail-banner-skyscraper"
                            bannerMeta={bannerMeta}
                            type={device}
                            breakpoint={breakpoint}
                            v2
                        />
                    </div>
                )}
            </React.Fragment>
        );
    },
);

class RightRail extends Component {
    processSearchBoxResponse = (response = {}) => {
        return Object.keys(response).length
            ? response.Results.map(result => {
                  const {
                      product_name,
                      pdp_url: blogUrl,
                      thumbnailimage,
                      blogdate,
                  } = (result && result.Document) || {};
                  const blogTitle = product_name && product_name[0];
                  const vanityUrl = blogUrl && blogUrl[0];
                  const blogThumbnail = thumbnailimage && thumbnailimage[0];
                  let blogDateString;
                  try {
                      blogDateString = Helpers.date.toDateString(new Date(blogdate && blogdate[0]), true);
                      if (/undefined/i.test(blogDateString)) {
                          blogDateString = null;
                      }
                  } catch (e) {}

                  return {
                      blogTitle,
                      vanityUrl,
                      blogThumbnail: { src: blogThumbnail },
                      blogDate: blogDateString,
                  };
              })
            : [];
    };

    render() {
        const {
            bannerType,
            subscribeHref,
            subscribeLinkId,
            subscribeLinkType = 'modal',
            tags = [],
            categories = [],
            tagsHeader,
            page,
            subscribeClick,
            bannerMeta,
            hideBlogAd,
            hideSearchBox,
            originCountryCode,
            vanityUrl,
        } = this.props;
        const tagProps = {
            tagsHeader,
            tags,
            categories,
            page,
            bannerMeta,
            hideBlogAd,
            bannerType,
            hideSearchBox,
            processSearchBoxResponse: this.processSearchBoxResponse,
            vanityUrl,
        };
        return (
            <AboveTheFoldOnlyServerRender
                skipEval={deviceType => {
                    //skip if mobile or tablet
                    return deviceType === 'mobile' || deviceType === 'tablet';
                }}
                /* we can try lazy load later, but for now dont SSR for mobile and tablet since it's below the fold
            offset={300}
            placeholderStyle={{height: 950}}
            placeholderClassName={'lazyload right-rail-container'}*/
            >
                <div className="right-rail-container">
                    <SubscribeButton
                        handleClick={subscribeClick}
                        linkId={subscribeLinkId}
                        href={subscribeHref}
                        metricsElementType={subscribeLinkType}
                    />
                    <TagsSection {...tagProps} />
                </div>
            </AboveTheFoldOnlyServerRender>
        );
    }
}

export default RightRail;
