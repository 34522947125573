import React from 'react';
import PropTypes from 'prop-types';

import './grid.less';

function Column({ children, desktop, xl, large, medium, small, mobile, xs, xxs, className, hide=[], columnClass='col', style }) {
    const styles = hide.map(x => `hidden-${x}`);

    xl && styles.push(`xl-${xl}`);
    desktop && styles.push(` desktop-${desktop}`);
    large && styles.push(` large-${large}`);
    medium && styles.push(` medium-${medium}`);
    small && styles.push(` small-${small}`);
    mobile && styles.push(` mobile-${mobile}`);
    xs && styles.push(` xs-${xs}`);
    xxs && styles.push(` xxs-${xxs}`);

    return (
        <div className={`grid-column${columnClass ? ` ${columnClass}` : ''} ${styles.join(' ')}`} style={{  ...style  }}>
            <div className={`span-container${className ? ` ${className}` : ''}`}>{children}</div>
        </div>
    );
}

Column.propTypes = {
    desktop: PropTypes.number,
    xl: PropTypes.number,
    large: PropTypes.number,
    medium: PropTypes.number,
    small: PropTypes.number,
    mobile: PropTypes.number,
    xs: PropTypes.number,
    xxs: PropTypes.number,
    className: PropTypes.string,
    hide: PropTypes.arrayOf(PropTypes.string),
    columnClass: PropTypes.string,
    style: PropTypes.object,
};

function Grid({ children, className }) {
    return <div className={`grid grid-container${className ? ` ${className}` : ''}`}>{children}</div>;
}

Grid.defaultProps = {
    className: null,
    children: null,
};

Grid.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([Column, PropTypes.arrayOf(Column)]),
};

export { Grid, Column };




