import React from 'react';
import { Button } from '@hpstellar/core';
import ButtonVisId from '@hpstellar/core/visId/Button';
import { ArrowRight } from '@hpstellar/icons';

import BlogEmailForm from '../../../components/blog-email-form';
import './subscribe-btn.less';

class SubscribeButton extends React.Component {
    constructor() {
        super();
        this.state = {
            modalOpen: false,
        };
    }

    static metricsAttributes = {
        modal: () => ({ element: 'div' }),
        anchor: props => {
            let { href } = props || {};
            let metricsProps = {
                element: 'a',
            };
            if (href) {
                metricsProps.href = href;
                metricsProps.target = '_blank';
            }
            return metricsProps;
        },
    };

    getButtonAttributes = type => {
        if (type === 'modal') {
            return { onClick: () => this.setState({ modalOpen: true }) };
        }
        return {};
    };

    render() {
        let { metricsElementType, linkId, match: { url } = {}, text, size, visID } = this.props;
        let { modalOpen } = this.state;
        let metricsAttributes = SubscribeButton.metricsAttributes[metricsElementType || 'anchor'];
        metricsAttributes = metricsAttributes ? metricsAttributes(this.props) : {};

        let buttonAttributes = this.getButtonAttributes(metricsElementType);

        return (
            <React.Fragment>
                {React.createElement(
                    (metricsAttributes && metricsAttributes.element) || 'a',
                    {
                        ...metricsAttributes,
                        className: 'subscribe-btn-container',
                        'data-gtm-category': 'linkClick',
                        'data-gtm-id': 'content',
                        'data-gtm-value': 'subscribe-to-newsletter',
                    },
                    visID ?
                    (<ButtonVisId
                        className="primary subscribeButton"
                        theme="dark"
                        {...buttonAttributes}
                        size={size}
                        variation="secondary"
                    >
                        {text || 'Subscribe to newsletter'}
                    </ButtonVisId>) : (<Button
                        className="primary subscribeButton"
                        color="dark"
                        {...buttonAttributes}
                        endIcon={<ArrowRight size="s" />}
                        size={size}
                    >
                        {text || 'Subscribe to newsletter'}
                    </Button>)
                )}
                {metricsElementType === 'modal' && modalOpen && (
                    <BlogEmailForm
                        key={url}
                        isOpen={modalOpen}
                        handleClose={() => this.setState({ modalOpen: false })}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default SubscribeButton;
