import React, {Component} from 'react';
import Tags from './tags';
import { fetchTags } from '../../../blog-list-actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class TagsContainer extends Component {

	static defaultProps = {
		filters: {templateKey: 'blog', hosted: 'true', blogArchive: 'false'},
		postFilters: {},
		aggregates: {"tags" : "tags"},
		sort: null,
		size: 0,
		bucketSize: 10,
		from: 0,
	}

	componentDidMount() {
		const {tags} = this.props;
		if(tags.length === 0) {
			const {filters, postFilters, aggregates, sort, size, bucketSize, from } = this.props;
			this.props.fetchTags(filters, postFilters, aggregates, Object.keys(aggregates), sort, size, from, bucketSize);
		}
	}

	render() {
		const { topTags={}, tags=[], header="", match } = this.props;

		return (
			<Tags header={header} tags={tags.length ? tags : topTags} match={match}/>
		);
	}

}

const mapStateToProps = (state, ownProps) => {

  return {
	topTags: state.blogListInfo.topTags,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchTags: (filters, post_filters, aggregates,
      aggregateFilters, sort, size, from,bucketSize) => dispatch(fetchTags(filters, post_filters, aggregates,
        aggregateFilters, sort, size, from, bucketSize)),
  }
}

export default connect(
	mapStateToProps,
    mapDispatchToProps
)(withRouter(TagsContainer) );
