import React, {Component} from 'react';
import ArticleArchives from './article-archives';
import { fetchArticleArchives } from '../../../blog-list-actions';
import { connect } from 'react-redux';

class ArticleArchivesContainer extends Component {

  constructor(props) {
    super(props);
    this.filters = {blogArchive: 'true', templateKey: 'blog', hosted: 'true'};
    this.sort = {"blogDate": "desc"};
    this.size = 14;
    this.aggregates = {
      "all_years" :  "year",
      "all_months" : "month",
      "all_categories" : "categories",
    };
    this.aggregateFilters = Object.keys(this.aggregates);
    this.bucketSize = 30;
    this.post_filters = {};
    this.from = 0;
  }

  componentDidMount() {
    const {articleArchives=[]} = this.props;
    //avoid extra call on SSR
    if(articleArchives.length === 0){
      this.props.fetchArticleArchives(this.filters, {}, this.aggregates, this.aggregateFilters, this.sort, this.size, 0, this.bucketSize);
    }
  }

  render() {
    const {articleArchives=[]} = this.props;

    return (
      <ArticleArchives articleArchives={articleArchives}/>
    );
  }


}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchArticleArchives: (filters, post_filters, aggregates,
      aggregateFilters, sort, size, from, bucketSize) => dispatch(fetchArticleArchives(filters, post_filters, aggregates,
        aggregateFilters, sort, size, from, bucketSize)),
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    articleArchives: state.blogListInfo.articleArchives,
  }
}

export default connect(
	mapStateToProps,
    mapDispatchToProps
)(ArticleArchivesContainer);
