import React, {Component} from 'react';
import Chip from '../../chips/chips'

class Tags extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tags: [],
    }
  }

  static sortTags = (tags) => {
    const sortedTags = [...tags];
    sortedTags.sort(function(a, b){
      if(a < b) { return -1; }
      if(a > b) { return 1; }
        return 0;
      });
    return sortedTags;
   }


  static getDerivedStateFromProps(nextProps, prevState) {

    if(Array.isArray(nextProps.tags) && nextProps.tags.length) {

      const {tags} = nextProps;
      return {
        tags: Tags.sortTags(tags)
      }
    }
    else if(typeof nextProps.tags === 'object' && Object.keys(nextProps.tags).length) {
      const { aggregations } = nextProps.tags;
      const { buckets } = aggregations.tags.tags_filtered;
      const tags = buckets.map((bucket) => {
        return bucket['key'];
      });

      return {
        tags: Tags.sortTags(tags)
      }
    }

    return null;
  }



  render() {

    const {tags=[]} = this.state;
    const {header="", match} = this.props;

    return (
      <ul>
      {
        tags.map((tag, key)=>{
            return (
					    <Chip match={match} header={header} key={tag + key} title={tag} element='li' />
            );
        })
      }
      </ul>
    );
  }
}

export default Tags
