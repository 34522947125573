import * as React from 'react';

import { Typography, Link } from '@hpstellar/core';

import './image-banner.less';

const ImageBanner = ({
    linkText,
    image: {
        url: { desktop: desktopImageSrc, mobile: mobileImageSrc },
        alt,
    },
    title,
    titleMobile,
    subtitle,
    description,
    descriptionMobile,
    gtmActions,
    className,
    onBannerClick,
    offset = false,
}) => {
    return (
        <div
            className={className ? `imageBanner ${className}` : `imageBanner`}
            role="button"
            onClick={onBannerClick}
            tabIndex={0}
        >
            <picture>
                {mobileImageSrc && <source media="(min-width:1024px)" srcSet={desktopImageSrc} />}
                <img className={offset ? 'image offset' : 'image'} src={mobileImageSrc || desktopImageSrc} alt={alt} />
            </picture>
            {title && (
                <Typography tag="div" className="content">
                    <div>
                        {title && (
                            <Typography variant="titleL" tag="div">
                                {title || titleMobile}
                            </Typography>
                        )}
                        {subtitle && (
                            <Typography variant="boldM" tag="div">
                                {subtitle}
                            </Typography>
                        )}
                        {description && <Typography>{description || descriptionMobile}</Typography>}
                    </div>
                    {linkText && (
                        <Link tag="button" className="link" gtmActions={gtmActions}>
                            {linkText}
                        </Link>
                    )}
                </Typography>
            )}
        </div>
    );
};

export default ImageBanner;
