import React from 'react';
import { MetricsElement } from 'react-metrics';
import { QuickFilterButton } from '@hpstellar/core';

import Link from '../../../shared/components/hyperlink';

import './chips.less';

const chip = props => {
    let { title = '', header = '', element = 'span', match } = props;
    let { basename, dir, filters } = (match && match.params) || {};
    let path = `/${dir}/article-filters/${filters}`;
    let link = '/tech-takes/article-filters/tags=' + title;
    let isActive = link === path;

    if (typeof title === 'object') {
        title = title['key'];
    }

    const titleDisplay = title.split('-').join(' ').toLowerCase();
    const eventId = header.replace(' ', '').toLowerCase() + ':clicked';

    return (
        <MetricsElement
            element={element}
            data-metrics-event-name="linkClick"
            data-metrics-event="e_linkClick"
            data-metrics-link-id={eventId}
            className="chip"
        >
            {
                <Link to={isActive == false ? link : dir}>
                    <QuickFilterButton className="tagChip" size={'small'} isActive={isActive}>
                        {titleDisplay}
                    </QuickFilterButton>
                </Link>
            }
        </MetricsElement>
    );
};

export default React.memo(chip);
