import React, { Component } from 'react';
import { ContentCard, Carousel, Typography } from '@hpstellar/core';
import { Button } from '@hpstellar/core';

import withWindowResize from '../../../ui/components/window-resize';
import HyperLink from '../../../shared/components/hyperlink';

import { getContentCardContent } from '../../../utility/setHtml';
import ContentCarouselVisId from './content-carousel-visid';

import './content-carousel.less';
class ContentCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            relatedBlogs: [],
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (typeof nextProps.relatedBlogs === 'object' && Object.keys(nextProps.relatedBlogs).length) {
            const { hits = [] } = nextProps?.relatedBlogs?.hits || {};
            const relatedBlogs = hits.reduce((acc, hit) => {
                const { blogThumbnail, blogTitle, vanityUrl, description } = hit['_source'];
                const blog = { blogThumbnail, blogTitle, vanityUrl, description };
                acc.push(blog);
                return acc;
            }, []);

            return {
                relatedBlogs,
            };
        }

        return null;
    }

    CAROUSEL_SETTINGS = {
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
        arrows: true,
        infinite: true,
        equalHeight: true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };

    render() {
        const { relatedBlogs } = this.state;
        const { header = null, device, visId } = this.props;

        if (!relatedBlogs?.length) return null;

        if (visId) {
            return <ContentCarouselVisId relatedBlogs={relatedBlogs} />;
        }

        return (
            <div className="content-carousel-container">
                {header && (
                    <Typography variant="titleL" tag="h3">
                        {header}
                    </Typography>
                )}
                <div className={`content-carousel-${device}-component`}>
                    {
                        <Carousel {...this.CAROUSEL_SETTINGS}>
                            {relatedBlogs.map((relatedBlog, i) => {
                                const { blogThumbnail, blogTitle, vanityUrl } = relatedBlog;

                                //TODO: see why vanity url includes tech-takes url
                                const url = vanityUrl.replace('tech-takes/', '');

                                return (
                                    <HyperLink to={vanityUrl}>
                                        <ContentCard
                                            className="carouselContentCard"
                                            title={blogTitle}
                                            {...getContentCardContent({ title: blogTitle })}
                                            image={{
                                                url: {
                                                    desktop: blogThumbnail.src,
                                                    alt: blogThumbnail.alt,
                                                },
                                                className: 'contentCardImg',
                                                imageContainerClassName: 'contentCardImgContainer',
                                            }}
                                            type="vertical"
                                            ctaComponent={
                                                <Button color="dark" size="small">
                                                    Read More
                                                </Button>
                                            }
                                        />
                                    </HyperLink>
                                );
                            })}
                        </Carousel>
                    }
                </div>
            </div>
        );
    }
}

export default withWindowResize(ContentCarousel);
