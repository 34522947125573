import React from 'react';
import { getContentCardContent } from '../../../utility/setHtml';
import { ProductCarousel, ContentCard } from '@hpstellar/core/visId';

import useDeviceInfo from '../../../hooks/useDeviceInfo';
import { Helpers } from '../../../core/src/helpers';
import './content-carousel-visid.less';

export default ({ title = 'Related articles', relatedBlogs }) => {
    const { device } = useDeviceInfo(['device'], 'visId');
    if (!relatedBlogs || !relatedBlogs.length) return null;
    return (
        <ProductCarousel title={title} className="visid-relatedarticles-container ">
            {relatedBlogs.map((relatedBlog, i) => {
                const { blogThumbnail, blogTitle, vanityUrl, description } = relatedBlog;
                const url = /^tech-takes/.test(vanityUrl)
                    ? Helpers.getBasenameRelativePath(`/${vanityUrl}`.replace(/^\/\//, '/'))
                    : url;
                const isDesc = description ? "" : "desc-doesnot-exists";
                return (
                    <ContentCard
                        key={blogTitle}
                        className="article-content-card"
                        buttons={[
                            {
                                children: 'Read more',
                                target: '_blank',
                                to: url,
                                variation: 'primary',
                                theme: 'dark',
                                className: `${isDesc}`,
                            },
                        ]}
                        description={device !== 'xsmall' ? description : undefined}
                        imageProps={blogThumbnail}
                        titleTypographyProps={{ tag: 'h2', className: 'content-card-title' }}
                        descriptionTypographyProps={{ className: 'content-card-description' }}
                        title={blogTitle}
                        {...getContentCardContent({ title: blogTitle })}
                    />
                );
            })}
        </ProductCarousel>
    );
};
